<script>
/**
 * Topbar component
 */
import { menuItems } from "./horizontal-menu";
export default {
  data() {
    return {
      menuItems: menuItems,
    };
  },
  methods: {
    logout(){
      localStorage.removeItem('user');
      this.$router.push({ path: '/login' })
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
  },
  computed:{
    crumbs() {
        let pathArray = this.$route.path.split("/")
        pathArray.shift()
        
        //console.log("singgih: "+pathArray)
        let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
          console.log(breadcrumbArray[idx - 1])
          breadcrumbArray = breadcrumbArray[idx - 2]
              ? "/" + breadcrumbArray[idx - 2].path + "/" + breadcrumbArray[idx - 1].path + "/"  + path
              : breadcrumbArray[idx - 1]
              ? "/" + breadcrumbArray[idx - 1].path + "/" + path
              : "/" + path;
              
          return breadcrumbArray;
        }, [])
        return breadcrumbs;
      }
  }
};
</script>

<style>
#navid .nav-link {
  color: #FFF;
}

#navid .nav-link.active {
  background-color: #02a499;
}
</style>
<template>
  <header id="page-topbar" style="margin-bottom:10px">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo2trans.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo2trans.png" alt height="17" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo2trans.png" alt height="40" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo2trans.png" alt height="40" />
            </span>
          </router-link>
        </div>

        <div style="padding-top: 20px">
          <b-nav pills>
            <b-nav-item v-for="item in menuItems" :key="item" :active="$route.path == item.link? true:false" id="navid">
              <router-link
              :to="item.link"
              style="color:white"
              >
              <i :class="item.icon"></i>
              {{item.label}}

              </router-link>
            </b-nav-item>
          </b-nav>
        </div>
      </div>

      <div class="d-flex">
        <div class="dropdown d-none d-lg-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="initFullScreen"
          >
            <i class="mdi mdi-fullscreen"></i>
          </button>
        </div>

        <b-dropdown
          class="d-inline-block"
          right
          toggle-class="header-item"
          variant="white"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/user-4.jpg"
              alt="Header Avatar"
            />
          </template>
          <button class="dropdown-item text-danger" @click="logout()"> Logout</button>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
