<script>
import { layoutComputed } from "@/state/helpers";

import Vertical from "@/router/layouts/vertical";
import Horizontal from "@/router/layouts/horizontal";

export default {
  components: { Vertical, Horizontal },
  computed: {
    ...layoutComputed
  },
  data(){
    return{
      user: JSON.parse(localStorage.getItem("user")),
    }
  }
};
</script>

<template>
  <div>
    <Horizontal v-if="user.role === 'eksekutif'" :layout="layoutType">
      <slot />
    </Horizontal>
    <Vertical v-else :layout="layoutType">
      <slot />
    </Vertical>

    
  </div>
</template>